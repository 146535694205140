import { Box, TextField, Slider, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import { useSelector, useDispatch } from "react-redux";
import {
  updateWelcomeMessage,
  updateScreenWelcomeMessage,
  updateEditorialContent,
  updatePlaylistIntroMessageFrequency,
  updatePlaylistIntroMessage,
  updateRadioOneIntroMessageFrequency,
  updateRadioOneIntroMessage,
  updateNewFeature,
  updateWelcomeBackground,
  updateNewFeatureBackground,
  updateOnboardingMessage
} from "../app/configSlice";
import { SERVER_URL } from "../app/constant";
import { useUser } from "../auth/useUser";
import { ROLE } from "../app/constant";
import { useToken } from "../auth/useToken";
import { useState } from "react";
import GridSelectorModal from "../components/GridSelectorModal";


const imageUrls = [
  "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/welcomeBackground-default.jpg",
  "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Skill+Background+Default.png",
  // "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Orange+Background.jpg",
  // "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Red+Background.jpg",
  // "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Blue+Background.jpg",
  // "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Cyan+Background.jpg",
  // "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Yellow+Background.jpg",
  "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Winter+Image+1+Background.jpg",
  "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Winter+Image+2+Background.jpg",
  "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Winter+Image+3+Background.jpg",
  "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Winter+Image+4+Background.jpg",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Spring+1.png",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Spring+2.png",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Spring+3.png",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Spring+4.png",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Summer+1.png",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Fall+1.png",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Fall+2.png",
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Fall+3.png", 
 "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/background/Fall+4.png",  
];



function DynamicMessagePage() {
  const textFieldStyle = {
    mt: 2,
    backgroundColor: "white",
  }

  const dispatch = useDispatch();

  const welcomeMessage = useSelector((state) => state.config.welcomeMessage);
  const screenWelcomeMessage = useSelector(
    (state) => state.config.screenWelcomeMessage
  );
  const editorialcontent = useSelector(
    (state) => state.config.editorialcontent
  );
  const playlistIntroMessageFrequency = useSelector(
    (state) => state.config.playlistIntroMessageFrequency
  );

  const playlistIntroMessage = useSelector(
    (state) => state.config.playlistIntroMessage
  );

  const radioOneIntroMessageFrequency = useSelector(
    (state) => state.config.radioOneIntroMessageFrequency
  );

  const radioOneIntroMessage = useSelector(
    (state) => state.config.radioOneIntroMessage
  );

  let welcomeBackground = useSelector(
    (state) => state.config.welcomeBackground
  );

  let newFeatureBackground = useSelector(
    (state) => state.config.newFeatureBackground
  );

  const onboardingMessage = useSelector(
    (state) => state.config.onboardingMessage
  );

  const newFeature = useSelector((state) => state.config.newFeature);

  const user = useUser();
  const { role } = user;
  const [token, setToken] = useToken();
  const [welcomeImageLoader, setWelcomeImageLoader] = useState(false);
  const [newFeatureImageLoader, setNewFeatureImageLoader] = useState(false);

  const callOpenLoader = (prependName) => {
    switch(prependName) {
      case "welcomeBackground":
        setWelcomeImageLoader(true);
        break;
      case "newFeatureBackground":
        setNewFeatureImageLoader(true);
        break;
    }
  };

  const dispatchImage = (prependName, data) => {
    switch(prependName) {
      case "welcomeBackground":
        welcomeBackground = data;
        dispatch(updateWelcomeBackground(data));
        break;
      case "newFeatureBackground":
        newFeatureBackground = data;
        dispatch(updateNewFeatureBackground(data));
        break;
    }
  };

  const onImageChange = (e, prependName) => {
    callOpenLoader(prependName);
    fetch(`${SERVER_URL}/updateImage?prependName=${prependName}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: e.target.files[0],
    })
      .then((response) => {
        if (response.status == 200) {
          response.text().then((data) => {
            dispatchImage(prependName, data);
          });
        } else {
          throw response;
        }
        setWelcomeImageLoader(false);
        setNewFeatureImageLoader(false);
      })
      .catch((error) => {
        error.text().then((data) => {console.log(data)});
      });
  };


  const OnDefault = (prependName) => {
    dispatchImage(prependName, "https://cbc-alexa-config.s3.us-east-1.amazonaws.com/images/welcomeBackground-default.jpg");
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelection = (imageId) => {
    setSelectedImage(imageId);
    console.log("image id is ", imageId);
    console.log("selected image is ", imageUrls[imageId]);
    dispatchImage("welcomeBackground", imageUrls[imageId]);
  };



  return (
    <Box>
      <TextField
        id="outlined-multiline-static"
        label="Welcome message"
        multiline
        fullWidth
        defaultValue={welcomeMessage}
        onChange={(e) => dispatch(updateWelcomeMessage(e.target.value))}
        InputLabelProps={{ shrink: true }}
        sx={{
          backgroundColor: 'white'
        }}
      />
      <TextField
        id="outlined-multiline-static"
        label="Screen welcome message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={screenWelcomeMessage}
        onChange={(e) => dispatch(updateScreenWelcomeMessage(e.target.value))}
        InputLabelProps={{ shrink: true }}
      />

      <Box width={400} sx={{ mt: 2, mb: 2 }}>
        <Typography id="welcome-screen-upload" gutterBottom>
          Screen welcome image
        </Typography>
        <Stack
          direction="column"
          spacing={1}>
          <img src={welcomeBackground} style={{ width: 400 }} ></img>

          <Fade
            in={welcomeImageLoader}
            style={{ transitionDelay: welcomeImageLoader ? '600ms' : '0ms' }}
            unmountOnExit>
            <LinearProgress/>
          </Fade>

          <Stack
            direction="row"
            spacing={2}>
            <Button
              variant="contained"
              component="label"
              disabled={role != ROLE.ADMIN}>
              Upload
              <input hidden accept="image/*" multiple type="file" onChange={event => onImageChange(event, "welcomeBackground")}
              />
            </Button>

            {/* <Button
              variant="contained"
              component="label"
              // onClick={() => OnDefault("welcomeBackground")}
              onClick={}
              disabled={role != ROLE.ADMIN}>
              Default
            </Button> */}

    <GridSelectorModal images={imageUrls} onClick={handleImageSelection} />
      {/* {selectedBox !== null && (
        <Typography sx={{ mt: 4 }}>
          You selected box number: {selectedBox}
        </Typography>
      )} */}
          </Stack>
        </Stack>
      </Box>

      <TextField
        id="outlined-multiline-static"
        label="Onboarding Message"
        multiline
        fullWidth
        sx={{ mt: 2 }}
        defaultValue={onboardingMessage}
        onChange={(e) => dispatch(updateOnboardingMessage(e.target.value))}
        InputLabelProps={{ shrink: true }}
      />


      <TextField
        id="outlined-multiline-static"
        label="Editorial content"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={editorialcontent}
        onChange={(e) => dispatch(updateEditorialContent(e.target.value))}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        id="outlined-multiline-static"
        label="New Feature"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={newFeature}
        onChange={(e) => dispatch(updateNewFeature(e.target.value))}
        InputLabelProps={{ shrink: true }}
      />
      <Box width={400} sx={{ mt: 2, mb: 2 }}>
        <Typography id="newfeature-screen-upload" gutterBottom>
          New feature image
        </Typography>
        <Stack
          direction="column"
          spacing={1}>
          <img src={newFeatureBackground} style={{ width: 400 }} ></img>

          <Fade
            in={newFeatureImageLoader}
            style={{ transitionDelay: newFeatureImageLoader ? '600ms' : '0ms' }}
            unmountOnExit>
            <LinearProgress/>
          </Fade>

          <Stack
            direction="row"
            spacing={2}>
            <Button
              variant="contained"
              component="label"
              disabled={role != ROLE.ADMIN}>
              Upload
              <input hidden accept="image/*" multiple type="file" onChange={event => onImageChange(event, "newFeatureBackground")}
              />
            </Button>

            <Button
              variant="contained"
              component="label"
              onClick={() => OnDefault("newFeatureBackground")}
              disabled={role != ROLE.ADMIN}>
              Default
            </Button>
          </Stack>
        </Stack>
      </Box>


      <Box width={300} sx={{ mt: 2 }}>
        <Typography id="input-slider" gutterBottom>
          Playlist intro message frequency
        </Typography>
        <Slider
          defaultValue={playlistIntroMessageFrequency}
          aria-label="Default"
          valueLabelDisplay="auto"
          onChange={(e) =>
            dispatch(updatePlaylistIntroMessageFrequency(e.target.value))
          }
        />
      </Box>
      <TextField
        id="outlined-multiline-static"
        label="Playlist intro message"
        multiline
        fullWidth
        sx={textFieldStyle}
        defaultValue={playlistIntroMessage}
        onChange={(e) => dispatch(updatePlaylistIntroMessage(e.target.value))}
        InputLabelProps={{ shrink: true }}
      />
      <Box width={300} sx={{ mt: 2 }}>
        <Typography id="input-slider" gutterBottom>
          Radio One intro message frequency
        </Typography>
        <Slider
          defaultValue={radioOneIntroMessageFrequency}
          aria-label="Default"
          valueLabelDisplay="auto"
          onChange={(e) =>
            dispatch(updateRadioOneIntroMessageFrequency(e.target.value))
          }
        />
      </Box>
      <TextField
        id="outlined-multiline-static"
        label="Radio One intro message"
        multiline
        fullWidth
        defaultValue={radioOneIntroMessage}
        sx={textFieldStyle}
        onChange={(e) => dispatch(updateRadioOneIntroMessage(e.target.value))}
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );
}

export default DynamicMessagePage;
